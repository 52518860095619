import { deleteObject, ref } from "@firebase/storage";
import { useFirebaseStorage } from "vuefire";
import { GoogleDriveMimeType } from "~/types/enums/GoogleDriveMimeType.enum";
import { ResourceType } from "~/types/enums/ResourceType.enum";
import { SupportedFileMimeType } from "~/types/enums/SupportedFileMimeType.enum";
import { ModelDatabaseData } from "~/types/ModelDatabaseData";
import { BaseModel } from "../base.model";

type EducationResourceConstructorParams = {
  id?: string;
  type: ResourceType;
  label: string;
  displayOrder?: number;
  isPublic?: boolean;
};

export type EducationResources = EducationResource[];

export class EducationResource extends BaseModel {
  id?: string;
  type: ResourceType;
  label: string;
  displayOrder: number;
  isPublic: boolean;

  constructor(data: EducationResourceConstructorParams) {
    super(data);

    this.id = data.id;
    this.type = data.type;
    this.label = data.label;
    this.displayOrder = data.displayOrder ?? 0;
    this.isPublic = data.isPublic ?? true;
  }

  static override fromMap(map: any): EducationResource {
    switch (map.type) {
      case ResourceType.file:
        return new FileResource(map);
      case ResourceType.googleDrive:
        return new GoogleDriveResource(map);
      case ResourceType.link:
        return new LinkResource(map);
      default:
        throw new Error("Invalid resource type");
    }
  }

  override toMap(): ModelDatabaseData {
    return super.toMap();
  }

  save() {}
  delete() {}
}

type FileResourceConstructorParams = Omit<
  EducationResourceConstructorParams,
  "type"
> & {
  mediaObject: MediaObject;
  mimeType: SupportedFileMimeType;
};

export class FileResource extends EducationResource {
  mediaObject: MediaObject;
  mimeType: SupportedFileMimeType;

  constructor(data: FileResourceConstructorParams) {
    super({
      ...data,
      type: ResourceType.file,
    });

    this.mediaObject = data.mediaObject;
    this.mimeType = data.mimeType;
  }

  get iconDetails() {
    // Check if the mimeType is any kind of image
    if (this.mimeType.includes("image")) {
      return { icon: "mdi:image", color: "#ff0000" };
    }

    switch (this.mimeType) {
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return { icon: "mdi:file-word", color: "#185abd" };
      case "application/pdf":
        return { icon: "mdi:file-pdf", color: "#ff0000" };
      case "text/plain":
        return { icon: "mdi:file-document-outline", color: "#6c757d" };
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return { icon: "mdi:file-powerpoint", color: "#d24726" };
      case "application/vnd.apple.pages":
        return { icon: "mdi:file", color: "#1a73e8" }; // Example: Adjust icon and color as needed
      case "application/vnd.apple.keynote":
        return { icon: "mdi:file", color: "#ff9500" }; // Example: Adjust icon and color as needed
      default:
        return { icon: "mdi:file-question", color: "#6c757d" }; // Fallback icon
    }
  }

  override async delete() {
    const storage = useFirebaseStorage();
    const fileRef = ref(storage, this.mediaObject.mediaHref);
    await deleteObject(fileRef);
  }
}

type GoogleDriveResourceConstructorParams = Omit<
  EducationResourceConstructorParams,
  "type"
> & {
  driveFileId: string;
  mimeType: GoogleDriveMimeType;
  generalPermissionConfig?: GoogleDrivePermissionConfig;
  fileData?: Uint8Array;
  createCopyFromTemplate?: boolean;
};

export class GoogleDriveResource extends EducationResource {
  driveFileId: string;
  mimeType: GoogleDriveMimeType;
  generalPermissionConfig?: GoogleDrivePermissionConfig;
  fileData?: Uint8Array;
  createCopyFromTemplate?: boolean;

  constructor(data: GoogleDriveResourceConstructorParams) {
    super({
      ...data,
      type: ResourceType.googleDrive,
    });

    this.driveFileId = data.driveFileId;
    this.mimeType = data.mimeType;
    this.generalPermissionConfig = data.generalPermissionConfig;
    this.fileData = data.fileData;
    this.createCopyFromTemplate = data.createCopyFromTemplate ?? false;
  }

  async createCopy({
    destinationFolderId,
    accessToken,
  }: {
    destinationFolderId: string;
    accessToken: string;
  }) {
    if (process.server) return;

    const response = await $fetch(`/api/drive/files/${this.driveFileId}/copy`, {
      method: "POST",
      headers: await useApiHeaders(),
      body: {
        destinationFolderId,
        accessToken,
      },
    });

    console.log("response from file copy", response == undefined);

    if (response == undefined) {
      throw new Error("No response from Google Drive API");
    }

    return new GoogleDriveResource({
      driveFileId: response,
      mimeType: this.mimeType,
      generalPermissionConfig: this.generalPermissionConfig,
      fileData: this.fileData,
      createCopyFromTemplate: false,
      label: this.label,
      displayOrder: this.displayOrder,
      isPublic: this.isPublic,
    });
  }

  async sync(options?: { accessToken?: string }) {
    console.log("syncing file", this.driveFileId);

    const response: any = await $fetch(`/api/drive/files/${this.driveFileId}/sync`, {
      method: "POST",
      headers: await useApiHeaders(),
      body: {
        accessToken: options?.accessToken,
      },
    });

    if (response == undefined) {
      throw new Error("No response from Google Drive API");
    }

    console.log("response from sync", response);

    this.label = response.name;
  }

  get url(): string {
    // Base URL for editing Google Drive files
    const baseUrl = "https://docs.google.com";

    // You might want to use the mimeType to differentiate the URL for Docs, Sheets, Slides, etc.
    // For simplicity, this example assumes you're handling Docs, Sheets, and Slides, which follow a similar URL pattern.
    // Adjust the paths as needed for different types of files.
    // Note: This function assumes that the mimeType validation and handling are done elsewhere if necessary.

    // Since the function doesn't determine the type of the Google Drive file (Doc, Sheet, Slide),
    // it returns a general URL that needs to be adjusted based on the actual file type.
    // The provided URL pattern works for Docs, Sheets, and Slides.
    // For Google Forms and other types, you'd need a different approach since their editing URLs might differ.
    let fileType = "document";

    switch (this.mimeType) {
      case GoogleDriveMimeType.docs:
        fileType = "document";
        break;
      case GoogleDriveMimeType.sheets:
        fileType = "spreadsheets";
        break;
      case GoogleDriveMimeType.slides:
        fileType = "presentation";
        break;
      case GoogleDriveMimeType.drawings:
        fileType = "drawings";
        break;
      default:
        return `${baseUrl}/file/d/${this.driveFileId}/view`;
    }

    return `${baseUrl}/${fileType}/d/${this.driveFileId}/edit`;
  }

  get color(): string {
    return this.mimeTypeProperties?.color || "";
  }

  get icon(): string {
    return this.mimeTypeProperties?.icon || "";
  }

  get mimeTypeProperties() {
    return GoogleDriveMimeTypes.find((mimeType) =>
      this.mimeType.includes(mimeType.value)
    );
  }
}

type LinkResourceConstructorParams = Omit<
  EducationResourceConstructorParams,
  "type"
> & {
  url: string;
};

export class LinkResource extends EducationResource {
  url: string;

  constructor(data: LinkResourceConstructorParams) {
    super({
      ...data,
      type: ResourceType.link,
    });

    this.url = data.url;
  }
}
